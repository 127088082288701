.discoveriesHeading {
  background-color: black;
  padding-bottom: 50px;
  padding-top: 15px;
}
.discoveriesHeading div,
.discoveriesHeading p,
.discoveriesHeading button {
  width: max-content;
  margin-left: 3%;
}
.inter {
  font-family: "Inter";
}

.discoveriesHeading h1 {
  font-size: 2.8rem;
}

.discoveriesHeading p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2rem;
}

.discoveriesHeading button {
  padding: 10px 40px;
  font-size: 1.4rem;
  border: none;
  border-radius: 10px;
  font-size: 1.4rem;
  background-color: white;
}
.discoveriesDesc {
  margin: 20px 10%;
}

.discoveriesDesc h2 {
  color: #00b7b7;
  font-size: 1.6rem;
}

.discoveriesDesc p {
  font-size: 1.4rem;
}

.heartValve,
.consultantResearch {
  width: 80%;
  margin: 20px 10%;
}

.consultantResearch h1 {
  color: #b7b7b7;
  font-size: 2.8rem;
}

.Opportunities {
  font-size: 1.4rem;
}

.consultantResearchCard {
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 30px;
  background-color: rgba(183, 183, 183, 0.2);
  border: 0.1px solid rgba(183, 183, 183, 0.1);
  border-radius: 10px;
  height: 215px;
  width: 100%;
}

.consultantResearchCard h3 {
  font-size: 2.8rem;
}
.consultantResearchCard p {
  font-size: 1.4rem;
}

@media Screen and (min-width: 375px) {
  .discoveriesHeading div,
  .discoveriesHeading p,
  .discoveriesHeading button {
    margin-left: 12%;
  }
}

@media Screen and (min-width: 425px) {
  .discoveriesHeading div,
  .discoveriesHeading p,
  .discoveriesHeading button {
    margin-left: 21%;
  }
  .Opportunities {
    width: 75%;
  }
}

@media Screen and (min-width: 576px) {
  .discoveriesHeading div,
  .discoveriesHeading p,
  .discoveriesHeading button {
    margin-left: 25%;
  }
}

@media Screen and (min-width: 768px) {
  .discoveriesHeading div,
  .discoveriesHeading p,
  .discoveriesHeading button {
    margin-left: 28%;
  }
  .discoveriesHeading h1 {
    font-size: 3.2rem;
  }
  .discoveriesHeading p {
    font-size: 1.6rem;
  }
  .consultantResearchCard {
    height: 320px;
  }
}

@media Screen and (min-width: 992px) {
  .discoveriesHeading div,
  .discoveriesHeading p,
  .discoveriesHeading button {
    margin-left: 31%;
  }
  .discoveriesHeading h1 {
    font-size: 4rem;
  }
  .discoveriesHeading p {
    font-size: 2rem;
  }
  .consultantResearch h1 {
    font-size: 3.2rem;
  }
  .Opportunities {
    font-size: 1.4rem;
    width: 80%;
  }
  .consultantResearchCard {
    height: 240px;
  }
}

@media Screen and (min-width: 1200px) {
  .discoveriesHeading div,
  .discoveriesHeading p,
  .discoveriesHeading button {
    margin-left: 36%;
  }
  .discoveriesHeading h1 {
    font-size: 4.4rem;
  }
  .discoveriesHeading p {
    font-size: 2.4rem;
  }
  .discoveriesDesc h2 {
    font-size: 2rem;
  }
  .discoveriesDesc p {
    font-size: 1.6rem;
  }
  .consultantResearchCard {
    width: 320px;
    height: 230px;
  }
}

@media Screen and (min-width: 1400px) {
  .discoveriesDesc,
  .consultantResearch {
    margin: 30px 15%;
  }
  .discoveriesDesc h2 {
    font-size: 2rem;
  }
  .discoveriesDesc p {
    font-size: 1.6rem;
  }
  .consultantResearch h1 {
    font-size: 3.6rem;
  }
  .Opportunities {
    font-size: 1.6rem;
  }
}
@media Screen and (min-width: 1600px) {
  .discoveriesDesc,
  .consultantResearch {
    margin: 30px 20%;
  }
}
