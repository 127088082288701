.navbar {
  height: 70px;
  font-size: 1.8rem;
  background-color: black;
  position: fixed !important;
  top: 0;
  width: 100vw;
  z-index: 10;
}

.nav-links-top-list a {
  text-decoration: none;
  color: white;
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.offCanvas {
  background-color: black;
}

.nav-links-top-list .active {
  color: red;
}

/*--- CSS For DropdownMenu.js---- */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.3s ease;
  position: absolute;
  background-color: black;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 17px;
  border: 1px solid rgba(94, 94, 94, 0.6);
  left: -122px;
  top: 5px;
}

.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
}

.dropdown_couIntakes:hover {
  visibility: visible;
  opacity: 1;
}

.dropdown-links a {
  height: 40px;
  width: 310px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 6px;
  margin: 0;
  color: white;
}

.heartNav {
  width: 70px;
}
.navbar a h2 {
  color: white;
}
