.pcSyndromweIntro,
.BiofeedbackTeraphy {
  background-color: #ffa6a6;
  padding: 20px;
}

.pcSyndromweIntro h1 {
  font-family: "Urbanist";
  font-size: 2.4rem;
}

.mplus2 {
  font-family: "M+PLUS+2";
}

.pcSyndromweIntro h3,
.mecsfP h3,
.BiofeedbackTeraphy h3,
.pcsManagement h3 {
  font-size: 2rem;
  font-weight: 600;
}

.introFirstLine {
  text-align: right;
}

.pcSyndromweIntro p,
.mecsfP ul li,
.BiofeedbackTeraphy ul li,
.pcsManagement p,
.pcsManagementInt p {
  font-size: 1.6rem;
}

.pcSyndromweIntro p span {
  font-size: 2.4rem;
}

/* ME/CSF Section */
.mecsfP,
.BiofeedbackTeraphy {
  padding: 20px;
}

.acupuncture,
.BiofeedbackTeraphyImg {
  width: 100%;
  height: 100%;
}

.pcsManagement {
  padding: 10px;
}

.pcsManagementInfo {
  background-color: #dfdfdf;
  padding: 30px;
  border-radius: 20px;
}

.pcsManagementInfo h3 {
  font-family: "Poppins";
  font-weight: bold;
  color: #808080;
}

@media Screen and (min-width: 425px) {
  .pcSyndromweIntro,
  .mecsfP,
  .pcsManagement {
    padding: 20px 5%;
  }

  .acupuncture,
  .BiofeedbackTeraphyImg {
    width: 400px;
    margin: auto;
  }

  .pcsManagement {
    padding: 20px;
  }
}

@media Screen and (min-width: 768px) {
  .pcSyndromweIntro,
  .mecsfP,
  .BiofeedbackTeraphy {
    padding: 30px 10%;
  }
  .pcSyndromweIntro h1 {
    font-size: 2.8rem;
  }

  .mecsfP {
    padding: 30px 5%;
  }
  .acupuncture {
    width: 100%;
  }
  /* ----- BiofeedbackTeraphy --- */
  .BiofeedbackTeraphy {
    height: 320px;
  }
  .BiofeedbackTeraphyImg {
    position: absolute;
    top: -3%;
    left: 10%;
    width: 330px;
    height: 340px;
  }

  .BiofeedbackTeraphyInfo {
    position: absolute;
    top: 9%;
    right: 8%;
    width: 40%;
  }
  .pcsManagement {
    padding: auto;
  }

  .pcsManagement {
    padding: 30px 8%;
  }

  .balloon,
  .AutomicNervousSystem {
    width: 250px;
    margin-left: 10px;
  }
}

@media Screen and (min-width: 992px) {
  .pcSyndromweIntro,
  .mecsfP,
  .BiofeedbackTeraphy {
    padding: 30px 20%;
  }
  .pcSyndromweIntro p span {
    font-size: 3rem;
  }
  .acupuncture {
    width: 300px;
  }

  .BiofeedbackTeraphyInfo {
    top: 15%;
    width: 50%;
  }
  .balloon {
    margin-left: 50px;
  }
}

@media Screen and (min-width: 1200px) {
  .pcSyndromweIntro,
  .mecsfP,
  .BiofeedbackTeraphy {
    padding: 30px 25%;
  }
  .pcSyndromweIntro h1 {
    font-size: 3.2rem;
  }
  .BiofeedbackTeraphyImg {
    left: 15%;
  }
  .BiofeedbackTeraphyInfo {
    top: 20%;
  }
  .pcsManagement {
    padding: 30px 15%;
  }
  .pcsManagementInt {
    padding: 0px 15%;
  }
}

@media Screen and (min-width: 1440px) {
  .BiofeedbackTeraphyImg {
    left: 25%;
  }
  .BiofeedbackTeraphyInfo {
    top: 25%;
    width: 40%;
    right: 12%;
  }
  .pcsManagement {
    padding: 30px 20%;
  }
  .pcsManagementInt {
    padding: 0px 20%;
  }
}

@media Screen and (min-width: 1600px) {
  .BiofeedbackTeraphyInfo {
    top: 25%;
    width: 30%;
    right: 25%;
  }
  .pcsManagement {
    padding: 30px 25%;
  }
  .pcsManagementInt {
    padding: 0px 25%;
  }
}
