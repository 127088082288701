@import url("https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Libre+Barcode+39+Extended+Text&display=swap");

.rencaHeading {
  color: red;
  font-size: 3.5rem;
  font-family: "Libre Barcode 39 Extended Text", system-ui;
}
.renucaHeading2 {
  margin-top: 50px;
  text-align: center;
  /* color: aliceblue; */
}
.heartbgHome {
  filter: drop-shadow(7px 7px 10px #eadfdf);
  width: 170px;
}
.hoempage {
  font-size: 1.6rem;
}
.landingSec {
  background-color: black;
  min-height: 500px;
}

.Establishmentsec {
  background-color: #f4f3f3;
}
.Establishmentsec > div {
  max-width: 1200px;
}
.section2SubHead {
  font-family: "K2D", sans-serif;
  font-size: 2rem;
}

.section2ImgHome {
  width: 250px;
  height: 250px;
  box-shadow: 4px 0px 4px rgb(114, 108, 108);
}
.section2Imgduo {
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: center;
  filter: drop-shadow(4px 0px 4px rgb(114, 108, 108));
}

.heartbgHome {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.3));
}
.rencaHeading span {
  display: block;
}
.section4Home {
  background-color: #e6e6e6;
}
.topImagesec3 {
  width: 200px;
  /* height: 200px; */
}
.imagesDivSec3 {
  flex-wrap: wrap;
}
.Imagessec3 {
  width: 300px;
  object-fit: cover;
  height: 200px;
  margin: 20px;
}
.bottomSec3 {
  width: 120px;
}
.section4Home {
  padding: 20px;
}
.clinicalProg h5 {
  font-size: 1.7rem;
}
.middleRow img {
  box-shadow: 4px 0px 4px rgb(114, 108, 108);
}
@media screen and (min-width: 425px) {
  .topImagesec3 {
    width: 300px;
  }
}

@media screen and (min-width: 768px) {
  .rencaHeading {
    font-size: 4.5rem;
  }
  .heartbgHome {
    width: 300px;
  }
  .section2Imgduo {
    width: 400px;
    height: 400px;
  }
  .topImagesec3 {
    width: 400px;
    /* height: 300px; */
  }
  .middleRow {
    padding: 10px 10% !important;
  }
  .imagesDivSec3 {
    width: 600px;
  }
  .clinicalProg {
    width: 300px;
  }
}
@media screen and (min-width: 992px) {
  .section2Imgduo {
    width: 450px;
    height: 450px;
  }
  .rencaHeading {
    font-size: 5.5rem;
  }
  .heartbgHome {
    width: 300px;
  }
  .topImagesec3 {
    width: 500px;
  }
  .imagesDivSec3 img {
    width: 200px;
    margin: 5px;
  }
  .section4Home {
    padding: 10px 10%;
  }
  .clinicalProg {
    width: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .heartbgHome {
    width: 400px;
  }
  .hoempage section {
    padding: 10px 20% !important;
  }
  .section4Home {
    padding: 10px 25%;
  }
}
