.dysautomiaApproachIntroWrapper {
    background-image: url("../../Assets/neural networks.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 65vh;
    width: 100%;
}

.dysautomiaApproachIntro {
    height: 65vh;
    width: 50%;
    clip-path: polygon(25% 100%, 100% 100%, 100% 0%, 0% 0%);
    background-color: rgba(224, 224, 224, 0.9);
    padding: 20px;
    box-sizing: border-box;
}
.alignCenter{
width: 75%;
}

.dysautomiaApproachIntro h1 {
    font-size: 2.2rem;
}

.aboutDysautomia {
    font-size: 1.8rem;
}
.DysautomiaContent{
    width: 85%;
    padding: 15px;
    margin:15px;
}
.DysautomiaContentPara1,.DysautomiaContentPara2{
    padding: 15px;
    margin:15px;
}



/*Impact and health component*/



.ImpAndHealthbrain{
    width: 300px;
}
.healthConditionsWithDysautonomia h1 ,.impactOfAutonomicImbalance h1{
    font-size: 3.5rem;
}
.impactOfAutonomicImbalance div,.healthConditionsWithDysautonomia div {
    font-size: 1.7rem;
}
.imbalanceAndHealthWrapper{
    width: 50%;
}
.ImpAndHealthbrainWrapper{
    width: 25%;
}



/*cognitiveDysfunction*/
.ImpOfAutonomic,.cognitiveDysfunction,.ImpOfAutonomic h1{
    margin: 30px 10px;        
}
.autonomicImbalanceWrapper{
    background-color: #EDEDED;
}
.cognitiveDysfunction,.ImpOfAutonomic div{
    font-size: 1.8rem;
}
.cognitiveDysfunctionWrapper,.ImpOfAutonomicWrapper{
    padding: 20px 10px;
    margin: 20px 10px;
    width: 35%;
}
.automicImbImg1,.automicImbImg2{
    width: 280px;
    height: 200px;
}


/* Treatment approaches */
.TreatmentAppsWrapper{
    width: 35%;
}
.headacheWrapper {
    width: 30%;
}
.headacheWrapper img{
    width: 500px;
}
.treatmentApps h1,.AutonomicImbConclusion h1{
    font-size: 3rem;
}
.treatmentApps p,.AutonomicImbConclusion p{
    font-size: 2rem;
    padding: 10px 15px;
}

@media screen and (max-width:767px) and (min-width:0px) {
    .imbalanceAndHealthWrapper{
        width: 80%;
    }
    .ImpAndHealthbrainWrapper{
        width: 100%;
    }
    .ImpactAndHealth{
        flex-direction: column;
        flex-flow: column-reverse;
    }
    .healthConditionsWithDysautonomia h1 ,.impactOfAutonomicImbalance h1{
        font-size: 2.5rem;
    }
    .impactOfAutonomicImbalance div,.healthConditionsWithDysautonomia div {
        font-size: 1.5rem;
    }
    .ImpAndHealthbrain{
        width: 250px;
    }
    .dysautomiaApproachIntro {
        height: 65vh;
        width: 100%;
        background-color: rgba(224, 224, 224, 0.9);
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        padding: 20px;
        box-sizing: border-box;
    }
    .dysautomiaApproachIntro h1 {
        font-size: 2rem;
    }
    
    .aboutDysautomia {
        font-size: 1.5rem;
    }
    .DysautomiaContent{
        width: 90%;
    }
    .alignCenter{
        width: 100%;
    }
    .DysautomiaContentPara1{
        padding: 15px 0px;
        margin:15px 0px;
    }
    .DysautomiaContentPara2{
        padding: 15px 0px;
        margin:15px 0px;
    }
  
    .cognitiveDysfunction,.ImpOfAutonomic div{
        font-size: 1.6rem;
    }
    .automicImbImg1,.automicImbImg2{
        width: 250px;
        height: auto;
    }
    .autonomicImbalanceWrapper{
        flex-direction: column;
    }
    .cognitiveDysfunctionWrapper,.ImpOfAutonomicWrapper{
        padding: 10px 10px 0px 10px;
        margin: 10px 10px 0px 10px;
        width: 80%;
    }
    .ImpOfAutonomic,.cognitiveDysfunction,.ImpOfAutonomic h1{
        margin: 10px 0px ;        
    }
    .treatmentBlock{
        flex-direction: column;
    }
    .TreatmentAppsWrapper{
        width: 80%;
    }
    .headacheWrapper {
        width: 80%;
    }
    .headacheWrapper img{
        width:300px;
    }
    .treatmentApps h1,.AutonomicImbConclusion h1{
        font-size: 2rem;
    }
    .treatmentApps p,.AutonomicImbConclusion p{
        font-size: 1.5rem;
        padding: 10px 15px;
    }
}
@media screen and (max-width:1024px) and (min-width:768px){
   
    .dysautomiaApproachIntro h1 {
        font-size: 2rem;
    }
    
    .aboutDysautomia {
        font-size: 1.5rem;
    }
    .healthConditionsWithDysautonomia h1 ,.impactOfAutonomicImbalance h1{
        font-size: 2.5rem;
    }
    .impactOfAutonomicImbalance div,.healthConditionsWithDysautonomia div {
        font-size: 1.5rem;
    }
    .TreatmentAppsWrapper{
        width: 40%;
    }
    .headacheWrapper {
        width: 45%;
    }
    .headacheWrapper img{
        width:300px;
    }
    .treatmentApps h1,.AutonomicImbConclusion h1{
        font-size: 2.5rem;
    }
    .treatmentApps p,.AutonomicImbConclusion p{
        font-size: 1.5rem;
        padding: 10px 15px;
    }
}
@media screen and (max-width:1440px) and (min-width:1025px){
    .dysautomiaApproachIntro h1 {
        font-size: 2.2rem;
    }
    .aboutDysautomia {
        font-size: 1.8rem;
    }
    .TreatmentAppsWrapper{
        width: 40%;
    }
    .headacheWrapper {
        width: 45%;
    }
    .headacheWrapper img{
        width:300px;
    }
    .treatmentApps h1,.AutonomicImbConclusion h1{
        font-size: 2rem;
    }
    .treatmentApps p,.AutonomicImbConclusion p{
        font-size: 1.5rem;
        padding: 10px 15px;
    }
}
@media screen and (max-width:2560px) and (min-width:1440px){
    .TreatmentAppsWrapper{
        width: 45%;
    }
    .headacheWrapper {
        width: 30%;
    }
   
}
