.Urbanist {
  font-family: "Urbanist";
}

.coronoyHeading {
  background-color: #e6e6e6;
  padding: 20px 10px;
}

.corony {
  background-color: #fafafa;
  padding: 8%;
  color: #000000;
}

.coronyCard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 25px;
}

.coronoyImage {
  width: 100%;
  height: 300px;
  /* object-fit: cover; */
}

.coronyCardData {
  padding: 10px;
}

.coronyCardData h3,
.coronyApproach h2 {
  font-size: 1.6rem;
}
.coronyCardData ul li,
.coronyApproach p,
.coronyLinks p {
  font-size: 1.4rem;
}
.coronyCardData ul {
  padding-left: 25px;
}

.coronyApproach {
  padding: 20px;
}

.coronyLinks h2 {
  background-color: #ebebeb;
  padding: 10px;
  width: max-content;
}
@media Screen and (min-width: 475px) {
  .corony {
    padding: 5%;
  }
  .coronoyImage {
    width: 350px;
    height: 250px;
    margin-top: 20px;
  }
  .coronyCardData {
    padding: 30px;
  }
  .coronyApproach {
    padding: 50px;
  }
  .coronyLinks {
    margin-left: 10%;
  }
}

@media Screen and (min-width: 768px) {
  .coronoyImage {
    margin-top: 0px;
  }
  .coronyApproach {
    padding: 30px 10%;
  }
}

@media Screen and (min-width: 992px) {
  .coronoyHeading {
    padding: 30px 10px;
  }
  .corony {
    padding: 30px 15% 20px;
  }
  .coronyApproach {
    padding: 30px 15%;
  }
  .coronyLinks {
    margin-left: 15%;
  }
}

@media Screen and (min-width: 1200px) {
  .coronoyHeading {
    padding: 40px 10px;
  }
  .corony {
    padding: 30px 20% 20px;
  }
  .coronyApproach {
    padding: 30px 20%;
  }
  .coronyApproach h2,
  .coronyLinks p {
    font-size: 1.8rem;
  }

  .coronyLinks {
    margin-left: 20%;
  }
}

@media Screen and (min-width: 1440px) {
  .corony {
    padding: 30px 20% 20px;
  }
  .coronyApproach {
    padding: 30px 25%;
  }
  .coronyLinks {
    margin-left: 25%;
  }
}
