
.facPositionAndClinicalAsg{
    width: 40%;
}
.facPositionAndClinicalAsgWrapper p{
     color: #696969;
     font-size: 1.7rem;
     font-family: "Roboto", sans-serif;
}
.facPositionAndClinicalAsg h1{
    font-size: 60px;
    font-weight: bold;
}
.facPosJoinNow{
    font-size: 2rem;
}
.symposiumsWrapper{
    color: #B7B7B7;
    width: 80%;
}
.symposiumsWrapper h1{
    font-size: 50px;
    font-weight: bold;
} 
.symposiumsWrapper p{
    font-size: 1.5rem;
    
}
.symposiumsCards, .facultyPositionsCards,.consultantOppCards{
    width: 60%;
}
.symposiumsCard,.facultyPositionsCard ,.consultantOppCard{
    width: 29rem;
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 4px -1px 17px 0px rgba(197, 197, 197, 0.75);
    -moz-box-shadow: 4px -1px 17px 0px rgba(197, 197, 197, 0.75);
    box-shadow: 4px -1px 17px 0px rgba(197, 197, 197, 0.75);
    background-color: #F8F8F8;
}
.consultantOppCard{
    border-top:1px solid black;
    border-radius: 0px 0px 20px 20px ;
}

.symposiumsCard h1 {
    font-size: 3.4rem;    
    font-weight: bold;
    color: #7EB983;
}
.symposiumsCard p {
    font-size: 2rem;
    
}
.consultantOppCard p,.awardsList p{
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
}
.facultyPositionsCards p{
    font-size: 1.6rem;
}


/*
faculty position */
.facultyImg{
    width: 300px;
    height: 280px;
}
.facultyPositionsCard h1{
    font-size: 3.2rem;  
    font-family: "Inria Serif", serif;  
}
.steps{
    font-weight: bold;
}
.stepHeading {
    color: #757575;
    font-weight: bold;
    font-size: 3rem;
}
.awardsWrapper h1{
    font-size: 5rem; 
    color: #B7B7B7;
    font-weight: bold;
}
.learnMoreBtn{
    font-size: 3rem;
}
.awardsList{
    width: 60%;
}
.awardsList div span{
    font-size: 20px;
}
.awardsList div p{
    font-family: "Roboto", sans-serif;
}


@media screen and (max-width:767px) and (min-width:0px) {
    .facPositionAndClinicalAsg{
        width: 80%;
    }
    .facPositionAndClinicalAsgWrapper p{
         color: #696969;
    }
    .facPositionAndClinicalAsg h1{
        font-size: 30px;
    }
    .facPosJoinNow{
        font-size: 2rem;
    }
    .symposiumsWrapper h1,.facultyPositionsCard h1{
        font-size: 25px;
    } 
    .symposiumsWrapper p{
        font-size: 1.3rem;
    }
    .symposiumsCards,.facultyPositionsCards,.consultantOppCards{
        width: 100%;
    }
    .symposiumsCard h1{
        font-size: 3rem;    
        font-weight: bold;
        color: #7EB983;
    }
    .symposiumsCard p{
        font-size: 1.7rem;
    }
    .stepHeading{
        font-size: 2rem;
    }
    .awardsList{
        width: 80%;
    }
    .awardsList p{
        line-break: anywhere;
    }
    
}
@media screen and (max-width:1024px) and (min-width:768px) {
    .symposiumsCards ,.facultyPositionsCards{
        width: 90%;
    }
    .facPositionAndClinicalAsg{
        width: 60%;
    }
    .facPositionAndClinicalAsg h1{
        font-size: 35px;
    }
    .symposiumsWrapper h1{
        font-size: 35px;
    } 
    .consultantOppCards{
        width: 100%;
    }
    .facultyPositionsCards,.symposiumsCards{
        width: 100%;
    }
    
}
@media screen and (max-width:1440px) and (min-width:1025px) {
    .symposiumsCards .facultyPositionsCards{
        width: 90%;
    }
    .symposiumsCards ,.facultyPositionsCards,.consultantOppCards{
        width: 100%;
    }
    .facPositionAndClinicalAsg{
        width: 60%;
    }
}
