.contactForm {
  background-color: black;
  padding: 20px;
  font-size: 1.6rem;
}
.contactForm img {
  width: 100%;
}
.contactLogo {
  color: #d15c5c;
  font-size: 3.5rem;
  font-family: "Libre Barcode 39 Extended Text", system-ui;
}

.hospitalImgSec p {
  color: white;
  font-size: 1.6rem;
}
.contactForm h3 {
  font-size: 2rem;
}
.contactForm input,
.contactForm textarea {
  outline: none;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid white;
  padding: 10px;
}
.contactForm label {
  color: white;
  margin-top: 10px;
  font-size: 1.6rem;
}
.footerLogo {
  width: 100px !important;
}
.footerSideHeading {
  color: #d15c5c;
}
.footerLinks {
  padding-left: 0;
}
.footerLinks li {
  color: white;
  cursor: pointer;
  list-style-type: none;
}
@media screen and (min-width: 425px) {
  .contactForm {
    padding: 20px 5%;
  }
  .contactForm img {
    width: 300px;
  }
}
@media screen and (min-width: 768px) {
  .contactForm {
    padding: 30px 10%;
  }
  .contactForm input,
  .contactForm textarea {
    width: 300px;
  }

  .footerLinks li {
    padding: 10px;
    padding-left: 0;
  }
}
@media screen and (min-width: 992px) {
  .contactForm {
    padding: 50px 12%;
  }
  .contactForm img {
    width: 400px;
  }
  .contactForm input,
  .contactForm textarea {
    width: 400px;
  }
}
@media screen and (min-width: 1200px) {
  .contactForm {
    padding: 20px 20%;
  }
}
